

const ClientTestimonial = () => {
  return (
    <section className="max-w-6xl mx-auto my-12 p-8 bg-white text-center">
      {/* Section Title */}
      <h2 className="text-4xl font-bold text-gray-800 mb-4">Client Testimonials</h2>
      <p className="text-gray-600 mb-12">
        Don't just take our word for it – here's what our clients have to say: Study Solutions is the best placement consultant in Delhi.
      </p>


      {/* Testimonials Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Testimonial 1 */}
        <div className="p-6 border rounded-lg shadow-lg">
          <div className="text-[#01b0f1] text-4xl mb-4">❝</div>
          <p className="text-gray-600 mb-6">
            I’m truly grateful to Study Solutions for managing my end-to-end manpower recruitment so efficiently. The entire process was handled professionally with top-quality work. Most of our hires have come through Study Solutions, and we couldn’t be happier with the results.
          </p>

          <div className="flex flex-col items-center">
            <img src="hr2.jfif" alt="Anjeet" className="w-20 h-20 rounded-full mb-4 object-cover" />
            <h3 className="text-lg font-bold text-gray-800">Bhaneesh Kumar</h3>
            <p className="text-gray-500">Assist. Manager Human Resource & Admin</p>
          </div>
        </div>

        {/* Testimonial 2 */}
        <div className="p-6 border rounded-lg shadow-lg">
          <div className="text-[#01b0f1] text-4xl mb-4">❝</div>
          <p className="text-gray-600 mb-6">
            I’ve never experienced such a high level of professionalism as I have with Study Solutions. Their quality database and efficient work really stand out. Thank you for implementing competency mapping in our organization so smoothly and in such a short time. Truly, the best placement consultants in Delhi, India.
          </p>

          <div className="flex flex-col items-center">
            <img src="hr1.webp" alt="Siddharth Khanna" className="w-20 h-20 rounded-full mb-4 object-cover" />
            <h3 className="text-lg font-bold text-gray-800">Vijay Nehra</h3>
            <p className="text-gray-500">Senior HR</p>
          </div>
        </div>

        {/* Testimonial 3 */}
        <div className="p-6 border rounded-lg shadow-lg">
          <div className="text-[#01b0f1] text-4xl mb-4">❝</div>
          <p className="text-gray-600 mb-6">
            We truly appreciate the effort your team has put into our recruitment process. I can proudly say that the candidates recruited through Study Solutions are of high quality. Even from just a few resumes, we were able to find the right people for our organization.
          </p>

          <div className="flex flex-col items-center">
            <img src="hr3.jfif" alt="Astha Sukhla" className="w-20 h-20 rounded-full mb-4 object-cover" />
            <h3 className="text-lg font-bold text-gray-800">Sneha Dandriyal</h3>
            <p className="text-gray-500">External Tech Consultant</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientTestimonial;
