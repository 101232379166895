import React from 'react';

const HeroSection = () => {
  return (
    <section
      className="relative bg-cover bg-center h-screen flex items-center justify-center"
      style={{ backgroundImage: `url('/HeroPic.jpg')` }}
    >
      <a
        href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96"
        className="absolute inset-0"
        style={{ zIndex: 5 }}
        title="Job hiring - Learn more"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="sr-only">Job hirings</span>
      </a>

      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Overlay */}

      <div className="relative z-10 text-center text-white">
        <h1 className="text-5xl font-bold mb-4">Study Solutions</h1>
        <p className="text-xl font-light mb-8">The best Placement Consultants in Delhi India</p>
        <div className="flex justify-center space-x-4">
          <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" className="bg-[#00AEEF] text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-white hover:text-black" target="_blank" rel="noopener noreferrer">
            Consultant Hiring
          </a>
          <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" className="border border-white text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-white hover:text-black relative z-20" target="_blank" rel="noopener noreferrer">
            Looking For Job
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
