import React from 'react';

const WhatWeDo = () => {
  const redirectUrl = 'https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96';

  return (
    <section className="max-w-5xl mx-auto my-12 p-8 bg-white">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">
        <a href={redirectUrl} className="text-blue-500 hover:underline">
          Leading Recruitment Consultants for IT & Non-IT Manpower Solutions
        </a>
      </h1>
      <p className="text-gray-700 mb-4">
        For over a decade, Study Solutions has been a trusted partner for companies across various sectors. We specialize in providing comprehensive HR services to clients in industries like Automotive, Construction, Engineering, Manufacturing, Consumer Durables, and more, both within India and overseas.
      </p>

      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        <a href={redirectUrl} className="text-blue-500 hover:underline">
          Why Choose Study Solutions?
        </a>
      </h2>
      
      <ul className="list-disc list-inside text-gray-700 mb-6">
        <li>We maintain an up-to-date, high-quality online talent database for our preferred clients.</li>
        <li>We source candidates using multiple methods including referrals, social networks, headhunting, advertising, and project-based searches.</li>
        <li>We offer end-to-end recruitment solutions, from sourcing profiles to conducting reference checks, ensuring a seamless hiring experience for our clients.</li>
        <li>Our strategic approach helps clients tap into a highly skilled talent pool, enabling faster and more effective recruitment.</li>
        <li>We focus on building long-term relationships with our clients to support their growth and help achieve their business goals.</li>
      </ul>

      <p className="text-gray-700 mb-4">
        Study Solutions is recognized as one of India’s fastest-growing and most reliable <a href={redirectUrl} className="text-blue-500 hover:underline">recruitment consultancies</a>. With over 12 years of HR expertise, we are a trusted partner for both IT and Non-IT recruitment services.
      </p>
      
      <p className="text-gray-700">
        With more than 11 years of experience, Study Solutions offers a wide range of services including <a href={redirectUrl} className="text-blue-500 hover:underline">placement consultancy</a>, staffing solutions, HR services, headhunting, and manpower recruitment across various industries.
      </p>
    </section>
  );
};

export default WhatWeDo;
