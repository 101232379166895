import React from 'react';

const FreeCareerTips = () => {
  const redirectUrl = 'https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96';

  const posts = [
    {
      id: 1,
      title: "The Future of Remote Work: Trends and Predictions",
      description: "With the rise of remote work, how will the job market evolve? Explore the future of remote work and its impact on various industries...",
      tags: ["Remote Work", "Job Trends", "Work from Home", "Future of Work"],
      categories: ["Career Insights", "Tech Trends"],
      image: "",
      link: redirectUrl,
    },
    {
      id: 2,
      title: "Top 10 Skills to Master for a Career in AI",
      description: "Artificial Intelligence is transforming industries. Discover the top 10 skills you need to develop if you want to thrive in the AI job market...",
      tags: ["AI", "Artificial Intelligence", "Tech Skills", "Career Growth"],
      categories: ["Career Consultants", "Tech Skills"],
      image: "",
      link: redirectUrl,
    },
    {
      id: 3,
      title: "How the 2024 Lok Sabha Elections Will Impact Job Opportunities in India",
      description: "The 2024 Lok Sabha elections are set to shape India's political and economic landscape, opening up new job opportunities across various sectors. Discover how these changes will influence hiring trends and job growth...",
      tags: ["Job Opportunities", "Lok Sabha 2024", "Career Trends", "India"],
      categories: ["Career Insights", "Economic Impact"],
      image: "/loksabha.jpg",
      link: redirectUrl,
    },
    {
      id: 4,
      title: "How to Write a Standout Tech Resume: 7 Best Tips for Success",
      description: "Crafting the perfect tech resume is crucial in a competitive job market. Learn the key tips to write a resume that stands out to recruiters in the tech field...",
      tags: ["Resume Writing", "Tech Jobs", "Career Tips", "Success"],
      categories: ["Career Advice", "Tech Jobs"],
      image: "/Career1.webp",
      link: redirectUrl,
    },
    {
        id: 5,
        title: "The Booming Tech Industry in Indore: Opportunities and Growth",
        description: "Indore is fast becoming a tech hub in India. Discover the growing opportunities in the tech sector in this emerging city...",
        tags: ["Indore", "Tech Industry", "Job Opportunities", "Tech Growth"],
        categories: ["Tech Jobs", "Career Insights"],
        image: "/Career2.jpg",
        link: redirectUrl,
      },
      {
        id: 6,
        title: "Choosing the Right IT Consultancy for Your Career Growth",
        description: "IT consultancies can be pivotal in shaping your tech career. Learn how to choose the best consultancy that aligns with your career goals and aspirations...",
        tags: ["IT Consultancy", "Career Growth", "Tech Jobs", "Career Tips"],
        categories: ["Tech Career", "Consultancy Services"],
        image: "/Career3.jpg",
        link: redirectUrl,
      }
  ];

  return (
    <section className="max-w-7xl mx-auto my-12 p-8 bg-white">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {posts.map((post) => (
          <div key={post.id} className="bg-white rounded-lg shadow-md p-4">
            {post.image && (
              <img src={post.image} alt={post.title} className="w-full h-48 object-cover rounded-t-lg mb-4" />
            )}
            <div className="mb-2">
              {post.categories.map((category, index) => (
                <span 
                  key={index} 
                  className="bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded-full mr-2">
                  {category}
                </span>
              ))}
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              {post.title}
            </h3>
            <p className="text-gray-600 mb-4">
              {post.description}
            </p>
            <a href={post.link} className="inline-block bg-white border border-gray-400 text-gray-800 px-4 py-2 rounded hover:bg-gray-100">
              Learn More
            </a>
            {post.tags.length > 0 && (
              <p className="text-gray-400 text-sm mt-4">
                Tags: {post.tags.join(", ")}
              </p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FreeCareerTips;
