import React from 'react';

const PrivacyPolicy = () => {
  return (
    <section className="max-w-6xl mx-auto my-12 p-8 bg-white text-gray-800">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">Effective Date: 01st May 2024</p>
      
      <p className="mb-4">
        At Study Solutions (“we”, “us”, “our”), we are deeply committed to safeguarding your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website <a href="https://studysolutions.xyz" className="text-blue-600 hover:underline">https://studysolutions.xyz</a> (the “Site”) and utilize our services. By accessing or using our Site, you agree to the terms outlined in this Privacy Policy.
      </p>

      <h2 className="text-2xl font-semibold mb-2">What Information We Collect</h2>
      
      <h3 className="text-xl font-semibold mb-2">1.1 Personal Information</h3>
      <p className="mb-4">
        When you apply for a job or provide information through our Site, we may collect personal details such as, but not limited to:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Your Name</li>
        <li>Contact details (e.g., email, phone number)</li>
        <li>Resume and cover letter</li>
        <li>Job application specifics</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">1.2 Usage Data</h3>
      <p className="mb-4">
        Additionally, we may collect information about how you interact with our Site, such as:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Your IP address</li>
        <li>Browser type and settings</li>
        <li>Device details</li>
        <li>Pages you visit and time spent on them</li>
        <li>Referring website or platform</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">1.3 Cookies and Tracking Tools</h3>
      <p className="mb-4">
        Our Site employs cookies and similar tracking technologies to improve your experience, analyze site traffic, and offer personalized content. You can manage your cookie preferences through your browser settings.
      </p>

      <h2 className="text-2xl font-semibold mb-2">How We Use Your Information</h2>
      <p className="mb-4">We use the information we collect for several purposes, including:</p>
      <ul className="list-disc ml-6 mb-4">
        <li>Job Processing: To handle and manage job applications you submit.</li>
        <li>Site Optimization: To improve our website’s functionality and user experience.</li>
        <li>Communication: To respond to your queries, provide customer support, and update you on job applications.</li>
        <li>Marketing: To send you promotional content, provided you have opted-in for such communications.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">When We Share Your Information</h2>
      <p className="mb-4">
        We do not sell or rent your personal information. We may share it under specific circumstances:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Service Providers: With third-party vendors who assist us in running our Site and delivering services (e.g., hosting).</li>
        <li>Legal Obligations: When required by law or to comply with legitimate requests from authorities.</li>
        <li>Business Changes: In the event of mergers, acquisitions, or the sale of assets.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Data Security</h2>
      <p className="mb-4">
        We take reasonable steps to secure your personal information from unauthorized access or disclosure. However, no internet-based system can guarantee 100% security, and we cannot ensure absolute protection.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Your Choices</h2>
      <p className="mb-4">You have rights regarding the personal information you provide to us:</p>
      <ul className="list-disc ml-6 mb-4">
        <li>Access and Correction: You can request access to or corrections of your personal data.</li>
        <li>Opt-Out: You can choose to stop receiving promotional communications from us at any time.</li>
        <li>Deletion: You can request that we delete your personal information, subject to any legal obligations.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Third-Party Links</h2>
      <p className="mb-4">
        Our Site may contain links to external websites. We are not responsible for the privacy practices or content of those third-party sites. We recommend reviewing their privacy policies when visiting.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Children’s Privacy</h2>
      <p className="mb-4">
        Our Site is not intended for children under 13. We do not knowingly collect personal data from children under 13. If we become aware of such data collection, we will delete it promptly.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Updates to This Privacy Policy</h2>
      <p className="mb-4">
        We may revise this Privacy Policy from time to time. Any changes will be reflected on this page, and we encourage you to review it regularly.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy or our data handling practices, please feel free to reach out to us at:
      </p>
      <address className="not-italic">
        Study Solutions<br />
        Email: <a href="mailto:support@studysolutions.xyz" className="text-blue-600 hover:underline">support@studysolutions.xyz</a>
      </address>
    </section>
  );

};

export default PrivacyPolicy;
