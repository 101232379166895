import React from 'react';

const Cards = () => {
  return (
    <div className="max-w-6xl mx-auto my-12 p-8 bg-white text-center">
      <h2 className="text-4xl font-bold text-gray-800 mb-6">the most Reliable Placement Consultants in Delhi</h2>
      <p className="text-[#01b0f1] font-semibold italic mb-6">
        Disclaimer: <span className="text-[#01b0f1] font-normal">We do not charge any kind of money to
             any candidate throughout the process & do not ask for documents in scan or hard copies.</span>
      </p>
      <p className="text-gray-600 leading-relaxed mb-12">
    With extensive experience in placement consultancy across India, and through our dedication, we’ve successfully provided our clients with exceptional services. That’s why we’ve maintained our reputation as one of the best placement consultants in Delhi for over a decade. Here’s what sets us apart as a leading Manpower Recruitment Agency.
    <br />
    At Study Solutions, we offer a wide range of services, including Placement Consultancy, Staffing, Recruitment, and HR Consultancy across various industries. We cater to both <span className="text-[#01b0f1] font-semibold">IT and Non-IT companies</span> nationwide.
</p>


      {/* Card Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="text-left">
          <img src="/Cards1.jpg" alt="Recruitment of Professionals" className="w-full h-48 object-cover mb-4" />
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" target="_blank" rel="noopener noreferrer">
              RECRUITMENT OF PROFESSIONALS
            </a>
          </h3>
          <p className="text-gray-600 mb-4">
            One of the greatest strengths of our agency is our ability to connect you with top talent.
            We specialize in providing highly skilled professionals for both IT and Non-IT recruitment,
            ensuring that your company always has access to the best candidates.
          </p>

          <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" className="text-[#01b0f1] font-semibold">Learn more &raquo;</a>
        </div>

        <div className="text-left">
          <img src="Cards2.jpg" alt="Competency Mapping" className="w-full h-48 object-cover mb-4" />
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" target="_blank" rel="noopener noreferrer">
              COMPETENCY MAPPING
            </a>
          </h3>
          <p className="text-gray-600 mb-4">
            Our team of skilled experts excels in every step of the recruitment process, from JD interviews to competency and role assessments.
            They are well-equipped to assess and match the skills of any candidate seeking employment, ensuring the right fit for your company.
          </p>

          <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" className="text-[#01b0f1] font-semibold">Learn more &raquo;</a>
        </div>

        <div className="text-left">
          <img src="Cards3.jpg" alt="Psychometric Test" className="w-full h-48 object-cover mb-4" />
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" target="_blank" rel="noopener noreferrer">
              PSYCHOMETRIC TEST
            </a>
          </h3>
          <p className="text-gray-600 mb-4">
            Wondering how to choose the right career path? We use a standardized and scientific approach to evaluate an individual’s mental abilities and behavioral traits. Our psychometric assessments, designed for up to 125 participants, provide in-depth insights along with professional consultation.
          </p>

          <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" className="text-[#01b0f1] font-semibold">Learn more &raquo;</a>
        </div>
      </div>
    </div>
  );
};

export default Cards;
