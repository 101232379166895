import React from 'react';

const TermsAndConditions = () => {
  return (
    <section className="max-w-6xl mx-auto my-12 p-8 bg-white text-gray-800">
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <p className="mb-4">Last updated: August 16, 2024</p>
      <p className="mb-4">Please read these Terms and Conditions carefully before using our services.</p>
      
      <h2 className="text-2xl font-semibold mb-2">Definitions and Interpretation</h2>

      <h3 className="text-xl font-semibold mb-2">Interpretation</h3>
      <p className="mb-4">
        Words with capitalized letters have specific meanings defined under this section. The same definitions apply whether these words appear in singular or plural form.
      </p>

      <h3 className="text-xl font-semibold mb-2">Definitions</h3>
      <p className="mb-4">For the purposes of these Terms and Conditions:</p>
      <ul className="list-disc ml-6 mb-4">
        <li><strong>Affiliate</strong> refers to any entity that controls, is controlled by, or shares common control with a party. Control means owning 50% or more of the voting shares or other management authority.</li>
        <li><strong>Country</strong> refers to: Delhi, India.</li>
        <li><strong>Company</strong> (referred to as "the Company", "We", "Us", or "Our") refers to Study Solutions, New Delhi.</li>
        <li><strong>Device</strong> means any device capable of accessing the Service, including a computer, mobile phone, or tablet.</li>
        <li><strong>Service</strong> refers to the website.</li>
        <li><strong>Terms and Conditions</strong> (also called "Terms") refer to these Terms and Conditions, which represent the entire agreement between You and the Company regarding the use of the Service.</li>
        <li><strong>Third-party Social Media Service</strong> refers to any services or content provided by third-party platforms, which may be made accessible via the Service.</li>
        <li><strong>Website</strong> refers to Study Solutions, accessible at <a href="https://studysolutions.xyz/" className="text-blue-600 hover:underline">https://studysolutions.xyz/</a>.</li>
        <li><strong>You</strong> means the individual accessing or using the Service, or the entity or company on whose behalf such individual is accessing or using the Service.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Acceptance of Terms</h2>
      <p className="mb-4">
        These Terms govern your use of the Service and represent the agreement between You and the Company. They outline the rights and obligations of users related to the Service.
      </p>
      <p className="mb-4">
        Your use of the Service is conditional upon your acceptance and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
      </p>
      <p className="mb-4">
        By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you should not access the Service.
      </p>
      <p className="mb-4">
        You confirm that you are over the age of 18, as the Company does not permit use of the Service by anyone under 18.
      </p>
      <p className="mb-4">
        Your use of the Service is also subject to our Privacy Policy, which explains how we collect, use, and disclose your personal information. Please read our Privacy Policy before using the Service.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Links to External Websites</h2>
      <p className="mb-4">
        Our Service may contain links to external websites or services not operated by the Company. We have no control over these websites or services and are not responsible for their content or privacy practices.
      </p>
      <p className="mb-4">
        You acknowledge that the Company is not liable for any damages or losses caused by or related to the use of third-party websites or services. We recommend that you review the terms and privacy policies of any external sites you visit.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Termination of Access</h2>
      <p className="mb-4">
        We reserve the right to terminate or suspend your access to the Service without notice for any reason, including breach of these Terms.
      </p>
      <p className="mb-4">
        Upon termination, your right to use the Service will cease immediately.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Limitation of Liability</h2>
      <p className="mb-4">
        Regardless of any damages you may incur, the Company's liability will be limited to the amount you paid through the Service or USD 100 if no purchase was made.
      </p>
      <p className="mb-4">
        To the maximum extent allowed by law, the Company and its suppliers are not liable for any indirect, incidental, or consequential damages, including loss of data, profits, or business interruption, related to the use or inability to use the Service, third-party software, or hardware.
      </p>
      <p className="mb-4">
        Some jurisdictions do not allow certain limitations of liability, so these exclusions may not apply to you. In such cases, the liability of each party will be limited to the fullest extent permitted by law.
      </p>

      <h2 className="text-2xl font-semibold mb-2">"AS IS" Disclaimer</h2>
      <p className="mb-4">
        The Service is provided "AS IS" and "AS AVAILABLE", with no warranties of any kind. The Company disclaims all warranties, express or implied, including those related to merchantability, fitness for a particular purpose, or non-infringement.
      </p>
      <p className="mb-4">
        We do not guarantee that the Service will meet your requirements, be uninterrupted, error-free, or secure, nor that any errors will be corrected.
      </p>
      <p className="mb-4">
        Certain jurisdictions do not allow limitations on implied warranties, so some of the above disclaimers may not apply to you.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Governing Law</h2>
      <p className="mb-4">
        The laws of Delhi, India, will govern these Terms and your use of the Service, except where conflicting laws apply.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Resolving Disputes</h2>
      <p className="mb-4">
        If you have any disputes or concerns regarding the Service, we encourage you to first contact us to resolve the issue informally.
      </p>

      <h2 className="text-2xl font-semibold mb-2">For European Union Users</h2>
      <p className="mb-4">
        If you are a resident of the European Union, you will benefit from any mandatory provisions of the laws in your country of residence.
      </p>

      <h2 className="text-2xl font-semibold mb-2">United States Compliance</h2>
      <p className="mb-4">
        You represent that you are not located in a country subject to a U.S. government embargo or listed on any U.S. government restricted parties list.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Severability and Waiver</h2>

      <h3 className="text-xl font-semibold mb-2">Severability</h3>
      <p className="mb-4">
        If any provision of these Terms is deemed invalid or unenforceable, that provision will be modified to fulfill its intended purpose, and the remaining provisions will remain in effect.
      </p>

      <h3 className="text-xl font-semibold mb-2">Waiver</h3>
      <p className="mb-4">
        The failure to exercise any right or enforce any provision of these Terms will not be considered a waiver of those rights.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Translation Disclaimer</h2>
      <p className="mb-4">
        If these Terms are translated, the original English version will prevail in case of any discrepancies.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Changes to These Terms</h2>
      <p className="mb-4">
        We reserve the right to update or change these Terms at any time. If we make significant changes, we will provide notice at least 30 days before the new terms take effect.
      </p>
      <p className="mb-4">
        By continuing to use the Service after changes take effect, you agree to the updated terms. If you disagree with the revised terms, please stop using the Service.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about these Terms, please contact us:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>By email: support@studysolutions.xyz</li>
      </ul>
    </section>
  );
};

export default TermsAndConditions;
