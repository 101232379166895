import React from 'react';

const Disclaimer = () => {
  return (
    <section className="max-w-6xl mx-auto my-12 p-8 bg-white text-gray-800">
      <h1 className="text-3xl font-bold mb-4">Disclaimer</h1>
      <p className="mb-4">Last updated: August 16, 2024</p>
      
      <h2 className="text-2xl font-semibold mb-2">Definitions and Interpretation</h2>
      
      <h3 className="text-xl font-semibold mb-2">Interpretation</h3>
      <p className="mb-4">
        Words with capitalized letters have specific meanings defined in this section. These definitions apply equally whether they appear in singular or plural form.
      </p>

      <h3 className="text-xl font-semibold mb-2">Definitions</h3>
      <p className="mb-4">For the purposes of this Disclaimer:</p>
      <ul className="list-disc ml-6 mb-4">
        <li><strong>Company</strong> (referred to as "the Company", "We", "Us" or "Our") refers to Study Solutions, New Delhi.</li>
        <li><strong>Service</strong> refers to the Website.</li>
        <li><strong>You</strong> refers to the individual using the Service, or the company, or legal entity that such an individual represents.</li>
        <li><strong>Website</strong> refers to Study Solutions, accessible from <a href="https://studysolutions.xyz/" className="text-blue-600 hover:underline">https://studysolutions.xyz/</a></li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Disclaimer</h2>
      <p className="mb-4">
        The information available on the Service is provided for general informational purposes only.
      </p>
      <p className="mb-4">
        The Company assumes no responsibility for any errors or omissions in the content provided on the Service.
      </p>
      <p className="mb-4">
        Under no circumstances shall the Company be held liable for any special, direct, indirect, or incidental damages, or any damages whatsoever arising from the use of the Service or its content. The Company reserves the right to modify or remove content on the Service at any time without prior notice. This Disclaimer was created using a disclaimer generator tool.
      </p>
      <p className="mb-4">
        The Company does not guarantee that the Service is free from harmful elements such as viruses.
      </p>

      <h2 className="text-2xl font-semibold mb-2">External Links Disclaimer</h2>
      <p className="mb-4">
        The Service may include links to external websites that are not controlled or maintained by the Company.
      </p>
      <p className="mb-4">
        The Company does not guarantee the accuracy, relevance, or completeness of any information on external sites linked to from the Service.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Errors and Omissions Disclaimer</h2>
      <p className="mb-4">
        The information provided by the Service is intended for general guidance only. Despite the Company's efforts to ensure that the content is accurate and up-to-date, errors may occur. Additionally, given the changing nature of laws and regulations, delays, omissions, or inaccuracies in the information may arise.
      </p>
      <p className="mb-4">
        The Company is not liable for any inaccuracies or omissions or for outcomes resulting from the use of the information provided.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Fair Use Disclaimer</h2>
      <p className="mb-4">
        The Company may include copyrighted material for purposes such as criticism, commentary, news reporting, education, and research. We believe this constitutes "fair use" under section 107 of the U.S. Copyright Act.
      </p>
      <p className="mb-4">
        If You wish to use copyrighted material from the Service beyond fair use, You must obtain permission from the copyright holder.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Views Expressed Disclaimer</h2>
      <p className="mb-4">
        The views and opinions expressed within the Service are those of the authors and do not necessarily represent the official policies or positions of the Company, other authors, agencies, or organizations.
      </p>
      <p className="mb-4">
        Comments made by users are solely the responsibility of the commenter. Users agree to take full responsibility and liability for any legal claims or disputes arising from comments they post. The Company reserves the right to delete any comments for any reason.
      </p>

      <h2 className="text-2xl font-semibold mb-2">No Responsibility Disclaimer</h2>
      <p className="mb-4">
        The information provided by the Service should not be viewed as a substitute for professional legal, accounting, or tax advice. If you require specific advice, consult with a qualified professional in the appropriate field.
      </p>
      <p className="mb-4">
        The Company and its suppliers are not liable for any damages, including indirect, incidental, or consequential damages, arising from your use of or inability to use the Service.
      </p>

      <h2 className="text-2xl font-semibold mb-2">"Use at Your Own Risk" Disclaimer</h2>
      <p className="mb-4">
        All information on the Service is provided "as is" without any warranties, express or implied. We make no guarantees regarding the accuracy, completeness, or timeliness of the information. Use of the information is at your own risk.
      </p>
      <p className="mb-4">
        The Company is not liable for any decisions made based on the information provided by the Service, or for any consequential damages arising from such decisions.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about this Disclaimer, you can contact us:
      </p>
      <ul className="list-disc ml-6">
        <li>By email: <a href="mailto:support@studysolutions.xyz" className="text-blue-600 hover:underline">support@studysolutions.xyz</a></li>
      </ul>
    </section>
  );
};

export default Disclaimer;
