import React from 'react';

const Best = () => {
  return (
    <section className="max-w-6xl mx-auto my-12 p-8 bg-white text-center">
      <p className="text-gray-600 uppercase tracking-wide mb-4">
        Placement Consultants delivering service in Delhi | Mumbai | Chennai | Ahmedabad | Bangalore | Gurgaon | Pune
      </p>
      
      <h2 className="text-4xl font-bold text-gray-800 mb-6">
        The best Placement Consultants in Delhi
      </h2>
      
      <p className="text-gray-600 leading-relaxed mb-8">
        Our consistent performance and exceptional support over the years have earned us the reputation of being one of the <span className="font-bold">top placement consultants in Delhi</span>. We excel at delivering recruitment services for both IT and Non-IT industries across the country.
        <br />
        At the core of our success is our focus on customer satisfaction. We never compromise on the quality of service we provide. Whether it’s meeting client requirements, aligning with job profiles, or understanding candidate needs, we prioritize every aspect to ensure the best outcomes.
      </p>

      
      <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" className="bg-[#01b0f1] text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-[#0093cc] mb-8 inline-block">
        Get Connected With Us
      </a>

      <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
        <img src="Best1.jpg" alt="group of professionals working hard" className="w-full h-auto object-cover" />
        <img src="Best2.jpg" alt="Another group of professionals very hard" className="w-full h-auto object-cover" />
        <img src="Best3.png" alt="Another group of professionals 2" className="w-full h-auto object-cover" />
      </div>
    </section>
  );
};

export default Best;
