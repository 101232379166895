import React from 'react';

const LookingForJob = () => {
  const redirectUrl = 'https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96';

  return (
    <section className="max-w-5xl mx-auto my-12 p-8 bg-white">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">
        <a href={redirectUrl} className="text-blue-500 hover:underline">
          Job Application | Leading Placement Consultants
        </a>
      </h1>
      <p className="text-gray-700 mb-4">
        Study Solutions is recognized as one of the top <a href={redirectUrl} className="text-blue-500 hover:underline">placement
             consultants in Delhi</a>, backed by over 12 years of experience in the HR domain. Our expert team connects candidates with job opportunities across India and overseas. We provide placements in Manufacturing, IT, Pharmaceuticals, Banking & Finance, Automobiles, Engineering, and many other industries.
      </p>
      <p className="text-gray-700 mb-4">
      Additionally, Study Solutions is regarded as a <a href={redirectUrl} className="text-blue-500 hover:underline">leading recruitment consultancy
         in Delhi</a>. Our comprehensive HR services include:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-6">
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Placement Consultancy</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Competency Mapping</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">IT Recruitment Services</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Staffing Solutions</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Executive Search & Head Hunting</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Career Consulting</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Top-Level Management Recruitment (CEO, CFO, COO)</a></li>
      </ul>
      <p className="font-semibold italic text-gray-700 mb-4">Disclaimer: Please review the following carefully</p>
      <p className="text-gray-700 mb-6">
        To all our stakeholders: We do not provide any foreign education services. While we offer job opportunities for both freshers and professionals, we do not charge any fees for job placements in India or overseas. We do not request any form of payment for securing overseas jobs. Though we assist with job placements, we do not guarantee job placements. Study Solutions is a Delhi-based consultancy operating on a PAN India level, and our official website is www.studysolutions.xyz.
      </p>
      <p className="text-gray-700 mb-6">
        By clicking on the button below, “Upload Resume,” you agree to our disclaimer and its terms.
      </p>
      <a href={redirectUrl}>
        <button className="bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600">
          Upload Resume
        </button>
      </a>
    </section>
  );

};

export default LookingForJob;
